import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const config = {
  apiKey: "AIzaSyBPU6Rd6BsvmO-D71-s3NunIT9a9axoiF0",
  authDomain: "volunteer-scheduling-2023.firebaseapp.com",
  projectId: "volunteer-scheduling-2023",
  storageBucket: "volunteer-scheduling-2023.appspot.com",
  messagingSenderId: "444601969034",
  appId: "1:444601969034:web:5cecf50fffe0e6cf134073"
};

const app = initializeApp(config);
const functions = getFunctions(app);

if (window.location.hostname === 'localhost') {
   connectFunctionsEmulator(functions, 'localhost', 5001);
}

export {
  functions,
};
