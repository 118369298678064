import { Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import Layout from './components/App/Layout';
import { ErrorPage } from './common/utils';

import { ScheduleLayout, UpdatePage, ConfirmationPage } from './pages/schedule';
import { LoginPage } from './pages/login';

export default function App(props) {
  const router = createBrowserRouter([
    {
      element: <Layout/>,
      children: [
        {
          path: 'schedule/:jobContactKey',
          element: <ScheduleLayout/>,
          children: [
            {index: true, element: <UpdatePage/>},
            {path: 'confirmed', element: <ConfirmationPage/>},
          ],
          errorElement: <ErrorPage message="Could not locate your volunteer schedule"/>,
        },
        {path: '*', element: <LoginPage/>},
      ],
      errorElement: <ErrorPage message="Service not available... please try again later."/>,
    },
  ]);
  return (
    <RouterProvider router={router}/>
  );
}
