import { useCallback, useEffect, useState } from 'react';
import VolunteerHour from './VolunteerHour';
import { useScheduleChanges } from '../../hooks/useScheduleChanges';
import { SCHEDULE_STATUS, CHECKIN_STATUS } from '../../common/config';

export default function Schedule({hours, onSave}) {
  let [changes, accept, decline, addComment, checkWrong, checkin] = useScheduleChanges();
  let [submitted, setSubmitted] = useState(false);

  let getChangedHours = () => hours.map(hour => changes[hour.id] || hour);

  let isComplete = hours.length === Object.keys(changes).length;
  let completedHours = Object.values(changes).filter(hour => hour.status === CHECKIN_STATUS.COMPLETED);
  let canSubmit = completedHours.length > 0;

  return (<>
    <div className=''>
      <p className='text-2xl font-bold text-center'>My Volunteer Schedule</p>
      <p className='text-xl pb-6 font-light text-center'>Please check-in below:</p>
    </div>
    <div className="grid grid-cols-1 gap-4 px-5 sm:grid-cols-2">
      {hours.map(hour => <ScheduledHour key={hour.id} hour={changes[hour.id] || hour} onAccept={accept} onDecline={decline} onComment={addComment} onWrong={checkWrong} onCheckin={checkin}/>)}
    </div>
    <div className="flex justify-center pt-10">
      <button className="btn btn-primary" disabled={!canSubmit} onClick={() => canSubmit ? onSave(getChangedHours()) : setSubmitted(true)}>Confirm Check In</button>
    </div>
    {/* {  !isComplete && <div className='text-sm text-center pt-5'>Please select an option for all hours above</div>}  */}
  </>);
}

export const ScheduledHour = ({hour, onAccept, onDecline, onComment, onWrong, onCheckin}) => {
  return (
    <div className="bg-zinc-100 py-3 pl-3 rounded-lg">
      <VolunteerHour {...hour}/>
      <div className="flex justify-center gap-4 my-2">
        <CheckinHour hour={hour} onClick={() => onCheckin({hour})}/>
        {/* <AcceptHour hour={hour} onClick={() => onAccept({hour})}/>
        <DeclineHour hour={hour} onClick={() => onDecline({hour})}/> */}
      </div>
      <div>
        {SCHEDULE_STATUS.DECLINED === hour.scheduleStatus
          && <DeclineHourComment hour={hour} onChange={(comment) => onComment({hour, comment})} />}
      </div>
      <div>
        {/* <SomethingIsWrong hour={hour} onChange={(isWrong) => onWrong({hour, isWrong})}/> */}
      </div>
    </div>
  );
}
const CheckinHour = ({hour, ...props}) => {
  const selected = (CHECKIN_STATUS.COMPLETED === hour.status);
  return (
    <button type="button" className={`btn py-4 px-8 ${selected ? 'bg-slate-700 text-white border-primary border-2' : 'bg-orange-500'}`} {...props}>
      Check In
    </button>
  );
};
const AcceptHour = ({hour, ...props}) => {
  const selected = (SCHEDULE_STATUS.ACCEPTED === hour.scheduleStatus);
  return (
    <button type="button" className={`btn py-4 px-8 ${selected ? 'bg-slate-700 text-white border-primary border-2' : 'bg-orange-500'}`} {...props}>
      Accept
    </button>
  );
};
const DeclineHour = ({hour, ...props}) => {
  const selected = (SCHEDULE_STATUS.DECLINED === hour.scheduleStatus);
  return (
    <button type="button" className={`btn py-4 px-8 ${selected ? 'bg-slate-700 text-white border-primary border-2' : 'bg-orange-500'}`} {...props}>
      Decline
    </button>
  );
};
const DeclineHourComment = ({hour, onChange, ...props}) => {
  const [comment, setComment] = useState(hour.comment || '');
  return (
    <label className=''>
      <div className='text-center'>Reason for declining:  </div>
      <div className='flex justify-center'>
      <input className="" type="text" name="comment" value={comment} onChange={(e) => setComment(e.target.value)} onBlur={() => onChange(comment)} required {...props}/>
      </div>
    </label>
  )
};
const SomethingIsWrong = ({hour, onChange, ...props}) => {
  //const [isWrongChecked, setWrongIsChecked] = useState(hour.wrong || false);
  return (
   
    <label className='text-sm flex justify-center pt-4'>
     Something wrong with your assignment?
      <input className="ml-3" type="checkbox" name="wrong" checked={hour.wrong || false} onChange={(e) => onChange(!hour.wrong)} required {...props}/>
       </label>
  )
};
