import { useCallback, useEffect, useMemo, useState } from 'react'
import { httpsCallable } from 'firebase/functions';
import { functions } from '../common/firebase';

const getScheduledHours = httpsCallable(functions, 'getScheduledHours');
const saveScheduledHours = httpsCallable(functions, 'saveScheduledHours');

export default function useVolunteerSchedule({jobContactKey}) {
  let [error, setError] = useState(null);
  let [loaded, setLoaded] = useState(false);
  let [saving, setSaving] = useState(false);

  let [schedule, setSchedule] = useState({
    hours: [],
    allowChanges: false,
  });

  useEffect(() => {
    setError(null);
    if (!jobContactKey?.length || loaded) {
      return;
    }
    getScheduledHours({jobContactKey})
      .then(({data}) => setSchedule(data))
      .catch((error) => setError(error))
      .finally(() => setLoaded(true));
  }, [jobContactKey]);

  let save = useCallback(async (hours = []) => {
    console.log('save', hours);
    // return;
    if (saving) {
      return false;
    }
    setSaving(true);
    // TODO: verify result status
    let result = await saveScheduledHours({jobContactKey, hours});
    setSchedule({hours, allowChanges: false});
    setSaving(false);
    return result;
  }, [jobContactKey]);

  return {
    schedule,
    save,
    saving,
    error,
    loading: !loaded,
  };
}
