import { Outlet, useNavigation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import {FullscreenLoadingPage} from '../../common/utils'

export default function Layout() {
  return (
    <>
      <Header/>
      <main className="flex-grow flex flex-col">
        <Outlet/>
      </main>
      <Footer/>
    </>
  )
}
