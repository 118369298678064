import { useCallback, useEffect, useMemo, useState } from 'react';

import { CHECKIN_STATUS, SCHEDULE_STATUS } from '../common/config';

export const useScheduleChanges = () => {
  let [scheduleChanges, setScheduleChanges] = useState({});

  let checkin = useCallback(({hour}) => {
    let newStatus = hour.status === CHECKIN_STATUS.COMPLETED ? CHECKIN_STATUS.CONFIRMED : CHECKIN_STATUS.COMPLETED;
    console.log("Status Test: " + newStatus);
    let changedHour = {...hour, status: newStatus};
    setScheduleChanges({...scheduleChanges, [hour.id]: changedHour});
  }, [scheduleChanges]);
 
  let accept = useCallback(({hour}) => {
    let changedHour = {...hour, scheduleStatus: SCHEDULE_STATUS.ACCEPTED};
    setScheduleChanges({...scheduleChanges, [hour.id]: changedHour});
  }, [scheduleChanges]);

  let decline = useCallback(({hour}) => {
    let changedHour = {...hour, scheduleStatus: SCHEDULE_STATUS.DECLINED};
    setScheduleChanges({...scheduleChanges, [hour.id]: changedHour});
  }, [scheduleChanges]);

  let addComment = useCallback(({hour, comment}) => {
    setScheduleChanges({...scheduleChanges, [hour.id]: {...hour, comment}});
  }, [scheduleChanges]);

  let checkWrong = useCallback(({hour, isWrong}) => {
    setScheduleChanges({...scheduleChanges, [hour.id]: {...hour, wrong: isWrong}});
  }, [scheduleChanges]);

  return [scheduleChanges, accept, decline, addComment, checkWrong, checkin];
};
