import { useCallback, useEffect, useState } from 'react';
import VolunteerHour from './VolunteerHour';

export default function Summary({hours}) {
  // Read-only
  return (
    <div className="flex flex-col gap-4">
      {hours.map(hour => <SummaryItem key={hour.id} hour={hour}/>)}
    </div>
  );
}

function SummaryItem({hour}) {
  let bgClass = hour.status === 'Completed' ? 'bg-green-500' : 'bg-red-500';
  return (
    <div className='pl-10 pr-10'>
      <VolunteerHour {...hour}/>

      <div className={`p-4 ${bgClass}`}>
        {hour.status}
      </div>
    </div>
  );
}
