import BeatLoader from 'react-spinners/BeatLoader';
import SyncLoader from 'react-spinners/SyncLoader';

export const Loading = (params) => {
  const active = params.active;
  const color = params.color || '#ff8200';
  return (
    <div className="text-center"><BeatLoader color={color} loading={active} {...params} /></div>
  );
}
export const LoadingPage = (params) => {
  return (
    <main style={{ padding: '1rem 0' }}>
      <Loading {...params}/>
    </main>
  );
}
export const FullscreenLoadingPage = (params) => {
  return (
    <div className="loading-page text-center py-8">
      <div className="text-lg mb-3">Elevation Church</div>
      <SyncLoader size="20px" margin="10px" color="#F96302"/>
    </div>
  );
}
export const ErrorPage = ({message = 'Unknown error. Please try again'}) => {
  return (
    <div className="error-page">
      <div>An error occurred.</div>
      <div>{message}</div>
    </div>
  );
}
