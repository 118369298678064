

export default function VolunteerHour({id, scheduleStatus, ...hour}) {
  //
  return (
    <div>
      <p className="font-bold flex justify-center">{hour.teamName}</p>
      <p>Name: {hour.name}</p>
      <p>Campus: {hour.campusName}</p>
      <p>Date: {hour.startDate}</p>
      <p className="font-bold">Time: {hour.weTime}</p>
      {/* <p>Week: {hour.week}</p> */}

       {/* hour {JSON.stringify({id, scheduleStatus, ...hour}, null, 2)}  */}
    </div>
  );
}
