import { useMemo } from 'react';

export default function Footer() {
  let copyrightYear = useMemo(() => (new Date).getFullYear(), []);
  return (
    <footer className="p-4 text-gray-400 flex items-center gap-1">
      <span>&copy; {copyrightYear} Elevation Church.</span>
      <a href="https://elevationchurch.org/terms-of-use/" className="font-semibold text-sm">Terms of Use</a>
      <span>|</span>
      <a href="https://elevationchurch.org/privacypolicy/" className="font-semibold text-sm">Privacy Policy</a>
    </footer>
  );
}
