
export const SCHEDULE_STATUS = {
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
  PENDING: 'Pending',
};

export const CHECKIN_STATUS = {
  COMPLETED: 'Completed',
  CONFIRMED: 'Confirmed',
};
