

export const LoginPage = () => {
  return (
    <div className="flex-grow flex flex-col items-center justify-center">
      <h3 className="text-2xl mb-8">Elevation Volunteer Scheduling</h3>
      <a href="https://myelevation.elevationchurch.org/" target="_blank" className="btn btn-primary px-8 py-4">Login to MyElevation</a>
    </div>
  );
}
