import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom';

import { ErrorPage, Loading, LoadingPage } from '../common/utils';
import useVolunteerSchedule from '../hooks/useVolunteerSchedule';
import Schedule from '../components/VolunteerSchedule/Schedule';
import Summary from '../components/VolunteerSchedule/Summary';

export const ScheduleLayout = () => {
  let {jobContactKey} = useParams();
  let {schedule, save, saving, loading, error} = useVolunteerSchedule({jobContactKey});

  if (error) {
    throw error;
  }
  return loading ? <LoadingPage/> : (
    <div className="pt-6">
      <Outlet context={{schedule, save, saving}}/>
    </div>
  );
};

export const UpdatePage = () => {
  let {schedule, save, saving} = useOutletContext();

  if (!schedule.allowChanges || !schedule.hours.length) {
    return <Navigate to='confirmed'/>;// replace/>;
  }

  return saving ? <Loading/> : (
    <div>
      <Schedule hours={schedule.hours} onSave={save}/>
    </div>
  )
}

export const ConfirmationPage = () => {
  let {schedule} = useOutletContext();

  if (schedule.allowChanges && schedule.hours.length) {
    return <Navigate to='..' replace/>;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl flex justify-center pb-6">Volunteer hours confirmed:</h2>
      <Summary hours={schedule.hours}/>
  
      {/* <h2 className="text-3xl flex justify-center pt-10 font-bold">To make any changes to your volunteer confirmation,</h2> */}
      {/* <h2 className="text-4xl flex justify-center"><a className="text-orange-500 font-bold" href="https://myelevation.com">Sign in with MyElevation</a></h2> */}
    </div>
  )
}
