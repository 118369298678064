
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import EcLogo from '../../static/ec-logo.png';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="flex items-center gap-2 px-2 bg-gray-50 drop-shadow-md">
      <Link to={`/`}><img src={EcLogo} className="w-[32px] my-2"/></Link>
      <div>Elevation Church</div>
      <Nav/>
    </header>
  );
}

function Nav() {
  let [active, setActive] = useState(false);

  return (
    <nav>
      {/* <button onClick={() => setActive(!active)}><FontAwesomeIcon icon={faBars} className="text-xl"/></button>
      <menu className={`flex flex-col text-center gap-2 py-2 fixed flex-none bg-slate-700 drop-shadow-md rounded px-2 right-4 ${active ? 'active' : 'opacity-0'}`}>
        <li className="px-2 bg-white rounded"><a href="https://elevationchurch.org/">Elevation Church</a></li>
        <li className="px-2 bg-white rounded"><a href="https://elevationchurch.org/popup/">Outreach Signup</a></li>
      </menu> */}
    </nav>
  );
}
